import styled from "styled-components";

export const Wrapper = styled.input`
  border: none;
  outline: none;
  border-radius: 5px;
  max-width: 8em;
  margin: 0.2em;
  font-family: "monospace";
`;
