//Outer
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { Button } from "../../controls";
import { Title } from "../../descriptions";
import { actionDecreaseNumberOfCLick, actionIncreaseNumberOfClick } from "../../../redux";

//Styles
import { Wrapper } from "./ShowSerialNumberPage.styles";

import serialNumber1 from "../../../images/serialNumber1.png";
import serialNumber2 from "../../../images/serialNumber2.png";
import next from "../../../images/next.png";
import previous from "../../../images/previous.png";

export function ShowSerialNumberPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleIncreaseClick = () => {
    dispatch(actionIncreaseNumberOfClick(1));
  };

  const handleDecreaseClick = () => {
    dispatch(actionDecreaseNumberOfCLick(1));
  };

  return (
    <Wrapper>
      <Title text={t("information")} />
      <p className="description">
        {t("serial-number-on-sticker")} ( <img src={serialNumber2} alt="sn" /> ).
      </p>
      <div className="img-container">
        <img src={serialNumber1} alt="Stella-bio" />
      </div>

      <div className="btn-container">
        <Button handle={handleDecreaseClick}>
          <img src={previous} alt="title" />
        </Button>
        <Button handle={handleIncreaseClick}>
          <img src={next} alt="title" />
        </Button>
      </div>
    </Wrapper>
  );
}
