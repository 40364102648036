import styled from "styled-components";
import { MyProps } from "../../../utils/theme";

export const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: ${({ theme }) => theme.colors.stellaBlue2};

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: 50% auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 80%;

    @media (min-width: 1100px) {
      padding: 50px 30px;
      width: 35%;
      margin: 15% auto;
    }

    h2 {
      margin: 15px;
      padding: 0;
      text-align: center;
      font-size: 0.6em;
    }
    a {
      font-size: 0.7em;
      margin: 10px;
      color: ${({ theme }) => theme.colors.stellaSealBlue};
    }
    button {
      background-color: ${({ theme }: MyProps) => theme.colors.stellaGrey};
      font-size: 0.9em;
      border: none;
      padding: 0.3em 2.7em;
      border-radius: 10px;
      color: white;
      margin-top: 20px;
      cursor: pointer;
    }
  }
`;
