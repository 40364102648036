
//Styles
import alert_1 from "../../../images/alert_1.png";
import alert_2 from "../../../images/alert_2.png";
import alert_3 from "../../../images/alert_3.png";
import { Wrapper } from "./WarningStella.styles";

const CLOSE_PAGES = "CLOSE_PAGES" as const;
const BATTERY_LEVEL_LOW = "BATTERY_LEVEL_LOW" as const;
const CABLE_EMG_DISCONNECT = "CABLE_EMG_DISCONNECT" as const;

type Props = {
  alert?: typeof CLOSE_PAGES | typeof BATTERY_LEVEL_LOW | typeof CABLE_EMG_DISCONNECT;
};

function WarningStella({ alert }: Props) {
  const displayAlert = () => {
    switch (alert) {
      case CLOSE_PAGES:
        return <img className="alert" src={alert_1} alt="" />;
      case CABLE_EMG_DISCONNECT:
        return <img className="alert" src={alert_2} alt="" />;
      case BATTERY_LEVEL_LOW:
        return <img className="alert" src={alert_3} alt="" />;
      default:
        return <span></span>;
    }
  };

  return <Wrapper>{displayAlert()}</Wrapper>;
}

export default WarningStella;
