//Outter
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

//Inner
import { actionDisplayFlagsOrLang } from '../../redux';

import { Wrapper } from './ChooseLanguage.styles';

export function ChooseLanguage() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const changeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target.value as string;
    i18n.changeLanguage(target);
    dispatch(actionDisplayFlagsOrLang(true));
  };
  return (
    <Wrapper className="card">
      <ul>
        <li>
          <div className={i18n.language === 'en' ? 'check' : 'uncheck'}></div>
          <input
            onChange={changeLanguage}
            type="radio"
            name="name"
            id="english"
            value="en"
            checked={i18n.language === 'en'}
          />
          <label htmlFor="english">English</label>
        </li>

        <li>
          <div className={i18n.language === 'pl' ? 'check' : 'uncheck'}></div>
          <input
            onChange={changeLanguage}
            type="radio"
            name="name"
            id="polish"
            value="pl"
            checked={i18n.language === 'pl'}
          />
          <label htmlFor="polish">Polski</label>
        </li>
      </ul>
    </Wrapper>
  );
}
