import styled from "styled-components";

export const Wrapper = styled.div`
  color: black;
  font-size: 0.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1;
  padding: 0.1em 0.5em;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
  font-variant: small-caps;
  font-weight: bold;
`;
