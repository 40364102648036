//Outer
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { Button } from "../../controls";
import { Title } from "../../descriptions";
import { actionDecreaseNumberOfCLick, actionIncreaseNumberOfClick } from "../../../redux";

//Styles
import { Wrapper } from "./ChangeBattery.styles";
import battery from "../../../images/battery.png";
import next from "../../../images/next.png";
import previous from "../../../images/previous.png";

function ChangeBattery() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleIncreaseClick = () => {
    dispatch(actionIncreaseNumberOfClick(1));
  };

  const handleDecreaseClick = () => {
    dispatch(actionDecreaseNumberOfCLick(1));
  };

  return (
    <Wrapper>
      <Title text={t("step-3")} />
      <div className="dsc">
        <p>
          {t("change-battery")}.<span> {t("dont-interupt-update")}.</span>
        </p>
        <p>{t("battery-must-be-charge")}.</p>
      </div>
      <div className="img-container">
        <img src={battery} alt="Stella-bio" />
      </div>

      <div className="btn-container">
        <Button handle={handleDecreaseClick}>
          <img src={previous} alt="title" />
        </Button>
        <Button handle={handleIncreaseClick}>
          <img src={next} alt="title" />
        </Button>
      </div>
    </Wrapper>
  );
}

export default ChangeBattery;
