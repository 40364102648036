import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .img-container {
    margin: 60px 0 60px 0;
    max-width: 90%;
  }

  .description {
    font-size: 0.6em;
    line-height: 1.2;
    font-family: "Exo";
    text-align: center;
    span {
      border: 2px solid white;
      font-size: 0.6em;
      font-weight: bold;
      padding: 0.2em 0.3em;
    }
    img {
      height: 1em;
      vertical-align: middle;
    }

    @media (min-width: 550px) {
      font-size: 0.8em;
    }

    @media (min-width: 1400px) {
      font-size: 0.9em;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
  p {
    margin: 10px 0;
  }
`;
