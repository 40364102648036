//Outter
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//Inner
import { actionDisplayFlagsOrLang } from '../../redux';

//Styles && Images
import pl from '../../images/pl.png';
import en from '../../images/en.png';
import { Wrapper } from './DisplayFlags.styles';

export function DisplayFlags() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const displayFlagSwitch = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return <img src={en} alt="" />;
      case 'pl':
        return <img src={pl} alt="" />;

      default:
        return <img src={en} alt="" />;
    }
  }, [i18n.language]);

  const handleItem = () => {
    dispatch(actionDisplayFlagsOrLang(false));
  };
  return <Wrapper onClick={handleItem}>{displayFlagSwitch}</Wrapper>;
}
