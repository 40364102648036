//Stella info
export const CLIENTS = 'CLIENTS' as const;
export const STELLA_CHOOSE_TO_UPDATE = 'STELLA_CHOOSE_TO_UPDATE' as const;
export const STELLA_UNCHOOSE_TO_UPDATE = 'STELLA_UNCHOOSE_TO_UPDATE' as const;

//Logic
export const INCREASE_NUMBER_OF_CLICK = 'INCREASE_NUMBER_OF_CLICK' as const;
export const DECREASE_NUMBER_OF_CLICK = 'DECREASE_NUMBER_OF_CLICK' as const;
export const ADD_STELLA_SELECT = 'ADD_STELLA_SELECT' as const;
export const OPEN_MODAL = 'OPEN_MODAL' as const;
export const CLOSE_MODAL = 'CLOSE_MODAL' as const;
export const CHILDREN_LENGTH_STEPPER = 'CHILDREN_LENGTH_STEPPER' as const;

//Language
export const DISPLAY_FLAGS_OR_LANGUAGES = 'DISPLAY_FLAGS_OR_LANGUAGES' as const;
