import {
  CLIENTS,
  INCREASE_NUMBER_OF_CLICK,
  DECREASE_NUMBER_OF_CLICK,
  STELLA_CHOOSE_TO_UPDATE,
  STELLA_UNCHOOSE_TO_UPDATE,
  ADD_STELLA_SELECT,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHILDREN_LENGTH_STEPPER,
  DISPLAY_FLAGS_OR_LANGUAGES,
} from './stellaInfoTypes';
import { StellaActions } from './stellaInfoActions';
import { StellaBIO } from '@egzotech/stella-bio-js';

export type ModalType = 'support' | 'update' | 'finish' | 'advanceUpdate' | 'error' | '';

export type StellaInfoState = {
  clients: StellaBIO[];
  stellaToUpdate: StellaBIO[];
  currentStepPosition: number;
  modalType: ModalType;
  currentChildrenStepperLength: number;
  displayFlag: boolean;
};

const initialState: StellaInfoState = {
  clients: [],
  stellaToUpdate: [],
  currentStepPosition: 0,
  modalType: '',
  currentChildrenStepperLength: 0,
  displayFlag: true,
};

export const stellaInfoReducer = (state = initialState, action: StellaActions) => {
  switch (action.type) {
    case CLIENTS:
      return {
        ...state,
        clients: action.clients,
      };
    case STELLA_CHOOSE_TO_UPDATE:
      if (state.stellaToUpdate.indexOf(action.data) >= 0) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        stellaToUpdate: [...state.stellaToUpdate, action.data],
      };
    case STELLA_UNCHOOSE_TO_UPDATE:
      if (state.stellaToUpdate.indexOf(action.data) < 0) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        stellaToUpdate: state.stellaToUpdate.filter((v) => v !== action.data),
      };
    case INCREASE_NUMBER_OF_CLICK:
      return {
        ...state,
        currentStepPosition: state.currentStepPosition + action.num,
      };
    case DECREASE_NUMBER_OF_CLICK:
      return {
        ...state,
        currentStepPosition: state.currentStepPosition - action.num,
      };
    case ADD_STELLA_SELECT:
      return {
        ...state,
        stellaToUpdate: state.clients.filter((client) => client.hasFirmwareUpdate(action.firmware)),
      };
    case OPEN_MODAL:
      return {
        ...state,
        modalType: action.modalType,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalType: '' as ModalType,
      };
    case CHILDREN_LENGTH_STEPPER:
      return {
        ...state,
        currentChildrenStepperLength: action.length,
      };
    case DISPLAY_FLAGS_OR_LANGUAGES:
      return {
        ...state,
        displayFlag: action.displayFlags,
      };
    default:
      return state;
  }
};
