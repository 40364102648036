//Outer
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { Button } from "../../controls";
import { Title } from "../../descriptions";
import { actionDecreaseNumberOfCLick, actionIncreaseNumberOfClick } from "../../../redux";

//Styles
import { Wrapper } from "./DisconnectCable.styles";
import stella from "../../../images/stella-1.png";
import next from "../../../images/next.png";
import previous from "../../../images/previous.png";

function DisconnectCable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleIncreaseClick = () => {
    dispatch(actionIncreaseNumberOfClick(1));
  };

  const handleDecreaseClick = () => {
    dispatch(actionDecreaseNumberOfCLick(1));
  };

  return (
    <Wrapper>
      <Title text={t("step-1")} />
      <div className="dsc">
        <p>
          <strong>{t("disconnect-emg-cable")}</strong>
          {t("from-update-stella")}.{" "}
        </p>
        <p>{t("cable-connected-during")}.</p>
      </div>
      <div className="img-container">
        <img src={stella} alt="Stella-bio" />
      </div>

      <div className="btn-container">
        <Button handle={handleDecreaseClick}>
          <img src={previous} alt="title" />
        </Button>
        <Button handle={handleIncreaseClick}>
          <img src={next} alt="title" />
        </Button>
      </div>
    </Wrapper>
  );
}

export default DisconnectCable;
