//Outter
import { ThemeProvider } from 'styled-components';

//Inner
import {
  ChangeBattery,
  ChooseStellaContainer,
  CloseAllPages,
  DisconnectCable,
  StartUpdate,
  Stepper,
  AdvanceUpdate,
  ChooseLanguage,
  DisplayFlags,
} from './components';
import { useAppSelector } from './hooks';
import { RootState } from './redux/rootReducer';
import { ShowSerialNumberPage } from './components/stepper/showSerialNumberPage';

//Styles
import { theme } from './utils/theme';
import { GlobalStyle } from './App.styles';

function App() {
  const displayFlag = useAppSelector((state: RootState) => state.stellaInfo.displayFlag);
  return (
    <div className="wrapper">
      {displayFlag ? <DisplayFlags /> : <ChooseLanguage />}
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Stepper>
          <StartUpdate />
          <DisconnectCable />
          <CloseAllPages />
          <ChangeBattery />
          <ShowSerialNumberPage />
          <ChooseStellaContainer />
          <AdvanceUpdate />
        </Stepper>
      </ThemeProvider>
    </div>
  );
}

export default App;
