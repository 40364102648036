export const theme = {
  spacing: {
    xs: 1.8,
    sm: 2.2,
    xl: 2.4,
  },
  colors: {
    stellaBlue: "#0095da",
    stellaBlue2: "#0094d9",
    stellaSealBlue: "#6fc8dd",
    stellaLightBlue: "#e6f8f8",
    stellaDarkGrey: "#565656",
    stellaDarkBlue: "#006699",
    stellaLightGrey: "#e9e9e9",
    stellaGrey: "#a0a0a0",
    warmGrey: "#a0a0a0",
    white: "#fff",
  },
  defaultBoxShadow: "0 3px 6px 0 rgba(0,0,0,0.4)",
};

export type MyProps = {
  theme: typeof theme;
};
