//Outter
import { useDispatch } from "react-redux";
import { useEffect } from "react";

//Inner
import { useAppSelector } from "../../hooks";
import { actionCurrentChildrenStepperLength } from "../../redux";

//Styles
import { Wrapper } from "./Stepper.styled";

function Stepper({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionCurrentChildrenStepperLength(children.length - 1));
  }, [children.length, dispatch]);

  const currentIndex = useAppSelector((state) => state.stellaInfo.currentStepPosition);
  return <Wrapper>{children[currentIndex]}</Wrapper>;
}

export default Stepper;
