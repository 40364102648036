import { DirectHubClient, DirectHubManager, StellaBIO } from "@egzotech/stella-bio-js";
import { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./redux/store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useManagerUpdate(
  manager: DirectHubManager,
  stella: StellaBIO | null,
  callback: (client: StellaBIO) => void,
  inputs: any[]
) {
  useEffect(() => {
    const update = (client: DirectHubClient) => {
      if (client instanceof StellaBIO && (stella === null || client === stella)) {
        callback(client);
      }
    };

    manager.events.addHandler("clientUpdate", update);

    return () => {
      manager.events.removeHandler("clientUpdate", update);
    };
  }, [manager, stella, callback, ...inputs]);
}
