//Outter
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { actionCloseModal } from "../../../../../redux";

//Styles
import { Wrapper } from "./ServiceModal.styles";

function ServiceModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    dispatch(actionCloseModal());
  };
  return (
    <Wrapper>
      <h2>{t("please-contact-us")}</h2>
      <a href="https://service.egzotech.com" rel="noreferrer" target="_blank">
        www.service.egzotech.com
      </a>
      <button onClick={handleCloseModal}>OK</button>
    </Wrapper>
  );
}

export default ServiceModal;
