import styled from "styled-components";

import { MyProps } from "../../../utils/theme";

export const Wrapper = styled.button`
  background-color: ${({ theme }: MyProps) => theme.colors.stellaSealBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
  padding: 0.3em 1.7em;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  margin: 10px 0;
  font-size: 0.7em;
  img {
    width: 60%;
    padding: 0;
    margin: 0;
  }

  &.disabled {
    pointer-events: none;
    background-color: #bfbfbf;
  }
`;
