//Outter
import React, { MouseEventHandler } from "react";

//Styles
import { Wrapper } from "./LinkText.styles";

type ReactNode = React.ReactChild | React.ReactFragment | React.ReactPortal | boolean | null | undefined;

type Props = {
  children: ReactNode;
  handle: MouseEventHandler;
};

function LinkText({ children, handle }: Props) {
  return <Wrapper onClick={handle}>{children}</Wrapper>;
}

export default LinkText;
