//Outter
import { MouseEventHandler, ReactNode } from "react";

//Inner
import { Wrapper } from "./Button.styled";

export type Props = {
  readonly children: ReactNode;
  handle: MouseEventHandler;
  fontsize?: string;
  disabled?: boolean;
};

function Button({ children, handle, fontsize, disabled }: Props) {
  return (
    <Wrapper className={"btn" + (disabled ? " disabled" : "")} onClick={handle} disabled={disabled ? true : false}>
      {children}
    </Wrapper>
  );
}

export default Button;
