import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  padding: 10px;
  font-size: 0.6em;
  top: 10px;
  right: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 8em;
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3), 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    justify-content: flex-start;
    margin: 0.2em;
    align-items: center;
  }
  label {
    cursor: pointer;
  }

  .check {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #53bbdc;
  }

  .uncheck {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #6fc8dd;
    opacity: 0.4;
  }

  input[type='radio'] {
    visibility: hidden;
  }

  span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2em;
    height: 1.2em;
    top: -5px;
    background-color: #c5e8f1;
    color: grey;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
  }
`;
