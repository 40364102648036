import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .img-container {
    margin: 60px 0 40px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  p {
    margin: 10px 0;
  }
`;
