//Outter
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Suspense } from "react";
import "./i18n";

//Inner
import App from "./App";
import store from "./redux/store";

ReactDOM.render(
  <Suspense fallback={<span>Loading...</span>}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
