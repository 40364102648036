//Outter
import { ReactNode } from "react";

//Styles
import { Wrapper } from "./Modal.styles";

type Props = {
  children: ReactNode;
};

function Modal({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

export default Modal;
