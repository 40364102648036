import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .img-container {
    width: 60%;
    margin: 30px 0 30px 0;

    img {
      width: 100%;
      height: auto;
    }
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    h2 {
      font-weight: 300;
      margin: 0;
    }
    span {
      font-style: normal;
      font-weight: 700;
    }

    p {
      margin: 10px 0;
      text-align: center;
    }
  }

  .dsc-advance-options {
    font-size: 0.5em;
    text-decoration: underline;
  }

  .italic {
    font-style: italic;
  }
`;
