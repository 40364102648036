import styled, { keyframes } from "styled-components";
import { MyProps } from "../../../../../utils/theme";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(365deg)
  }`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 50% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 80%;

  .update_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 20%;
      height: 20%;
      animation: 1s ${rotate} ease-in-out infinite;
      @media (min-width: 1100px) {
        width: 15%;
        height: 15%;
      }
    }
    h2 {
      font-size: 0.6em;
      margin-left: 5px;
      @media (min-width: 1100px) {
        font-size: 1em;
      }
    }
  }

  .update_image {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
    img {
      width: 80%;
      height: auto;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;

    p {
      text-align: center;
    }

    .bold {
      font-weight: bold;
      margin-bottom: 2em;
    }
    p + p span {
      font-weight: bold;
    }
  }

  @media (min-width: 1100px) {
    padding: 50px 30px;
    width: 35%;
    margin: 15% auto;
  }
`;
