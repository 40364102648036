import { Firmware, StellaBIO } from '@egzotech/stella-bio-js';
import { ModalType } from './stellaInfoReducer';
import {
  CLIENTS,
  INCREASE_NUMBER_OF_CLICK,
  DECREASE_NUMBER_OF_CLICK,
  STELLA_CHOOSE_TO_UPDATE,
  STELLA_UNCHOOSE_TO_UPDATE,
  ADD_STELLA_SELECT,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHILDREN_LENGTH_STEPPER,
  DISPLAY_FLAGS_OR_LANGUAGES,
} from './stellaInfoTypes';

export type StellaActions =
  | ReturnType<typeof actionClientsActive>
  | ReturnType<typeof actionIncreaseNumberOfClick>
  | ReturnType<typeof actionDecreaseNumberOfCLick>
  | ReturnType<typeof actionStellaToUpdate>
  | ReturnType<typeof actionStellaUnChooseToUpdate>
  | ReturnType<typeof actionStellaSelect>
  | ReturnType<typeof actionOpenModal>
  | ReturnType<typeof actionCloseModal>
  | ReturnType<typeof actionCurrentChildrenStepperLength>
  | ReturnType<typeof actionDisplayFlagsOrLang>;

export const actionClientsActive = (clients: StellaBIO[]) => {
  return {
    type: CLIENTS,
    clients,
  };
};

export const actionIncreaseNumberOfClick = (num: number) => {
  return {
    type: INCREASE_NUMBER_OF_CLICK,
    num,
  };
};

export const actionDecreaseNumberOfCLick = (num: number) => {
  return {
    type: DECREASE_NUMBER_OF_CLICK,
    num,
  };
};

export const actionStellaToUpdate = (data: StellaBIO) => {
  return {
    type: STELLA_CHOOSE_TO_UPDATE,
    data,
  };
};

export const actionStellaUnChooseToUpdate = (data: StellaBIO) => {
  return {
    type: STELLA_UNCHOOSE_TO_UPDATE,
    data,
  };
};

export const actionStellaSelect = (firmware: Firmware) => {
  return {
    type: ADD_STELLA_SELECT,
    firmware,
  };
};

export const actionOpenModal = (modalType: ModalType) => {
  return {
    type: OPEN_MODAL,
    modalType,
  };
};

export const actionCloseModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};

export const actionCurrentChildrenStepperLength = (length: number) => {
  return {
    type: CHILDREN_LENGTH_STEPPER,
    length,
  };
};

export const actionDisplayFlagsOrLang = (displayFlags: boolean) => {
  return {
    type: DISPLAY_FLAGS_OR_LANGUAGES,
    displayFlags,
  };
};
