//Outter
import { useDispatch } from "react-redux";

//Inner
import { actionCloseModal } from "../../../../../redux";

//Styles
import { Wrapper } from "./AdvanceUpdate.styles";

type Props = {
  description: string;
};

export function AdvanceUpdateModal({ description }: Props) {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(actionCloseModal());
  };
  return (
    <Wrapper>
      <h2>{description}</h2>
      <button onClick={handleCloseModal}>OK</button>
    </Wrapper>
  );
}
