//Styles
import { Wrapper } from "./Title.styled";

type Props = {
  text: string;
};
function Title({ text }: Props) {
  return <Wrapper>{text}</Wrapper>;
}

export default Title;
