//Outer
import { DirectHubManager, Firmware, StellaBIO } from "@egzotech/stella-bio-js";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { useManagerUpdate } from "../../../../hooks";
import { actionStellaToUpdate, actionStellaUnChooseToUpdate } from "../../../../redux";
import { HasUpdateInfo, WarningStella } from "../../../descriptions";

//Styles & Images
import stella_blue from "../../../../images/stella_blue.png";
import stella_bio_white_glow from "../../../../images/stella_bio_white_glow.png";
import checkbox_1 from "../../../../images/checkbox_1.png";
import checkbox_2 from "../../../../images/checkbox_2.png";
import { Wrapper } from "./Stella.styles";

type Props = {
  manager: DirectHubManager;
  data: StellaBIO;
  pendingFirmware: Firmware | null;
  selected: boolean;
};

function Stella({ manager, data, pendingFirmware, selected }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [betteryLevel, setBatteryLevel] = useState(data.batteryLevel);
  const [version, setVersion] = useState(data.osVersion);
  const divElement = useRef<HTMLDivElement>(null);

  const managerUpdateCallback = useCallback((client: StellaBIO) => {
    setBatteryLevel(client.batteryLevel);
    setVersion(client.osVersion);
  }, []);

  useManagerUpdate(manager, data, managerUpdateCallback, []);

  const activeStellaHanlder = () => {
    if (selected) {
      dispatch(actionStellaUnChooseToUpdate(data));
    } else {
      dispatch(actionStellaToUpdate(data));
    }
  };

  const hasUpdate =
    pendingFirmware && !data.hasFirmwareUpdate(pendingFirmware) ? <HasUpdateInfo text={t("current-version")} /> : null;

  const stellaToUpdateOrNot = (
    <Wrapper
      ref={divElement}
      className={selected || (pendingFirmware && !data.hasFirmwareUpdate(pendingFirmware)) ? "select" : ""}
      onClick={pendingFirmware && data.hasFirmwareUpdate(pendingFirmware) ? activeStellaHanlder : undefined}
    >
      <div className="stella-blue">
        {hasUpdate}
        <img src={stella_blue} alt="stella_blue" />
        <img className="glow" src={stella_bio_white_glow} alt="" />
      </div>

      <p>
        {t("serial-number")}: {data.serialNumber}
      </p>
      {pendingFirmware && data.hasFirmwareUpdate(pendingFirmware) ? (
        <WarningStella alert={betteryLevel < 50 ? "BATTERY_LEVEL_LOW" : undefined} />
      ) : null}
      {pendingFirmware && !data.hasFirmwareUpdate(pendingFirmware) ? null : (
        <img
          className="checkbox"
          src={selected || (pendingFirmware && !data.hasFirmwareUpdate(pendingFirmware)) ? checkbox_2 : checkbox_1}
          alt="checkbox"
        ></img>
      )}
    </Wrapper>
  );

  return stellaToUpdateOrNot;
}

export default Stella;
