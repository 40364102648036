//Outter
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { actionCloseModal } from "../../../../../redux";

//Styles
import { Wrapper } from "./DisplayError.styles";

type Props = {
  errorMsg: string;
};

export function DisplayError({ errorMsg }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    dispatch(actionCloseModal());
  };
  return (
    <Wrapper>
      <h2>{errorMsg}</h2>
      <button onClick={handleCloseModal}>{t("return")}</button>
    </Wrapper>
  );
}
