//Outer
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { Button } from "../../controls";
import { actionIncreaseNumberOfClick } from "../../../redux";
import { useAppSelector } from "../../../hooks";
import { RootState } from "../../../redux/rootReducer";

//Styles
import { Wrapper } from "./StartUpdate.styles";
import updateGear from "../../../images/updateGear.png";

function StartUpdate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stepperChildrenLength = useAppSelector((state: RootState) => state.stellaInfo.currentChildrenStepperLength);
  const handleClick = () => {
    dispatch(actionIncreaseNumberOfClick(1));
  };

  const handleClickAdvance = () => {
    dispatch(actionIncreaseNumberOfClick(stepperChildrenLength));
  };

  return (
    <Wrapper>
      <div className="heading">
        <h2 className="italic">
          Stella <span>BIO</span>
        </h2>
        <p className="italic">updater</p>
      </div>
      <div className="img-container">
        <img src={updateGear} alt="" />
      </div>
      <div className="heading">
        <p>{t("update-stella")}</p>
        <p>{t("follow-instructions")}</p>
      </div>
      <Button fontsize={"4em"} handle={handleClick}>
        {t("get-started")}
      </Button>
      <div className="heading" onClick={handleClickAdvance}>
        <p className="dsc-advance-options">{t("advance-version")}</p>
      </div>
    </Wrapper>
  );
}

export default StartUpdate;
