//Outter
import { DirectHubClient, DirectHubManager, Firmware, FirmwareStorage, StellaBIO } from "@egzotech/stella-bio-js";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Inner
import { Button } from "../../controls";
import { LinkText, Title } from "../../descriptions";
import { useAppSelector, useManagerUpdate } from "../../../hooks";
import { Stella } from "./stella";
import { RootState } from "../../../redux/store";
import { ServiceModal, UpdateFinish, UpdateModal } from "../../ui/modal/sumModal/";
import { Loader, Modal } from "../../ui";
import { actionClientsActive, actionDecreaseNumberOfCLick, actionOpenModal, actionStellaSelect } from "../../../redux";

//Styles & Images
import { Wrapper } from "./ChooseStellaContainer.styles";
import previous from "../../../images/previous.png";

FirmwareStorage.default = new FirmwareStorage(
  process.env.NODE_ENV === "production" ? "https://fw-ota-test.egzotech.com/" : "https://fw-ota-test.egzotech.com/"
);

function ChooseStellaContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [update, setUpdate] = useState(0);
  const [pendingFirmware, setPendingFirmware] = useState(null as Firmware | null);

  const modalType = useAppSelector((state: RootState) => state.stellaInfo.modalType);
  const clients = useAppSelector((state: RootState) => state.stellaInfo.clients);
  const stellaToUpdate = useAppSelector((state: RootState) => state.stellaInfo.stellaToUpdate);

  const manager = useMemo(() => new DirectHubManager("wss://direct-hub.egzotech.com"), []);

  useEffect(() => {
    FirmwareStorage.default.fetchPendingFirmware(StellaBIO.DEFAULT_OS_NAME).then((firmware) => {
      setPendingFirmware(firmware);
    });
  }, []);

  useEffect(() => {
    manager.start();

    const clientConnectHandler = (client: DirectHubClient) => {
      const newClients = [];

      for (const token in manager.clients) {
        const client = manager.clients[token];

        if (client instanceof StellaBIO) {
          newClients.push(client);
        }
      }
      dispatch(actionClientsActive(newClients));
    };

    const clientUpdateHandler = (client: DirectHubClient) => {
      if (!(client instanceof StellaBIO)) {
        return;
      }

      setUpdate(update + 1);

      if (pendingFirmware) {
        if (client.hasFirmwareUpdate(pendingFirmware)) {
        }

        if (client.isFirmwareUpdating()) {
        }
      }
    };

    manager.events.addHandler("clientIdentify", clientConnectHandler); //Nowa stella się połaczyła
    manager.events.addHandler("clientUpdate", clientUpdateHandler); //Stella sie zmieniły dane np.battery level
    manager.events.addHandler("clientLost", clientConnectHandler); //Stella się rozłaczy…ła

    return () => {
      manager.events.removeHandler("clientIdentify", clientConnectHandler);
      manager.events.removeHandler("clientUpdate", clientUpdateHandler);
      manager.events.removeHandler("clientLost", clientConnectHandler);
    };
  }, []);

  const handleDecreaseClick = () => {
    dispatch(actionDecreaseNumberOfCLick(1));
  };

  const handleSelectAllStella = () => {
    if (pendingFirmware !== null) {
      dispatch(actionStellaSelect(pendingFirmware));
    }
  };

  const handleOpenModal = () => {
    dispatch(actionOpenModal("support"));
  };

  const handleUpdate = () => {
    dispatch(actionOpenModal("update"));

    stellaToUpdate.forEach((item) => {
      if (pendingFirmware !== null) {
        item.updateFirmware(pendingFirmware);
      }
    });
  };

  useManagerUpdate(
    manager,
    null,
    (client) => {
      if (modalType !== "update") {
        return;
      }
      if (stellaToUpdate.every((stella) => !stella.isFirmwareUpdating())) {
        dispatch(actionOpenModal("finish"));
      }
    },
    [modalType, stellaToUpdate]
  );

  const clientsWithUpdate = clients.filter((a) => pendingFirmware && a.hasFirmwareUpdate(pendingFirmware));
  const clientsWithoutUpdate = clients.filter((a) => pendingFirmware && !a.hasFirmwareUpdate(pendingFirmware));

  clientsWithUpdate.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));
  clientsWithoutUpdate.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));

  const finalClients = clientsWithUpdate.concat(clientsWithoutUpdate);

  const stellaList = finalClients.map((client) => (
    <Stella
      manager={manager}
      selected={stellaToUpdate.indexOf(client) >= 0}
      key={client.token}
      data={client}
      pendingFirmware={pendingFirmware}
    />
  ));

  return (
    <Wrapper>
      {modalType === "support" ? (
        <Modal>
          <ServiceModal />
        </Modal>
      ) : modalType === "update" ? (
        <Modal>
          <UpdateModal />
        </Modal>
      ) : modalType === "finish" ? (
        <Modal>
          <UpdateFinish firmware={pendingFirmware} />
        </Modal>
      ) : null}
      <div className="title">
        <Title text={t("choose-setlla-bio")} />
        <span>{t("click-on-the-device")}</span>
      </div>

      <div className="scrolling-wrapper">{clients.length > 0 ? stellaList : <Loader />}</div>

      <Button handle={handleSelectAllStella}>{t("select-all")}</Button>
      <div className="btn-container">
        <Button handle={handleDecreaseClick}>
          <img className="previous" src={previous} alt="title" />
        </Button>
        {stellaToUpdate.length > 0 ? <Button handle={handleUpdate}>{t("update")}</Button> : null}
      </div>
      <LinkText handle={handleOpenModal}>{t("contact-service")}</LinkText>
    </Wrapper>
  );
}

export default ChooseStellaContainer;
