//Outer
import { useDispatch } from "react-redux";
import { FormEvent, useState, useEffect } from "react";
import { FirmwareStorage, ReportsStorage } from "@egzotech/stella-bio-js";
import { useTranslation } from "react-i18next";

//Inner
import { actionDecreaseNumberOfCLick } from "../../../redux";
import { Button, Input } from "../../controls";
import { actionOpenModal } from "../../../redux";
import { useAppSelector } from "../../../hooks";
import { RootState } from "../../../redux/rootReducer";
import { AdvanceUpdateModal, DisplayError, Loader, Modal } from "../../ui";

//Styles && images
import { Wrapper } from "./AdvanceUpdate.styles";
import serialNumber1 from "../../../images/serialNumber1.png";
import serialNumber2 from "../../../images/serialNumber2.png";
import previous from "../../../images/previous.png";

export function AdvanceUpdate() {
  //hooks
  const disptach = useDispatch();
  const { t } = useTranslation();
  const [serial, setSerial] = useState("");
  const [validSerialNumber, setValidSerialNumber] = useState(true);
  const [stellaHasUpdate, setStellaHasUpdate] = useState(false);
  const [clearValue, setClearValue] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);

  const modalType = useAppSelector((state: RootState) => state.stellaInfo.modalType);
  const stepperChildrenLength = useAppSelector((state: RootState) => state.stellaInfo.currentChildrenStepperLength);

  useEffect(() => {
    let newSerial = serial.toUpperCase();
    newSerial = newSerial.replace(/[0o]/gm, "O");
    newSerial = newSerial.replace(/[1ilL]/gm, "I");

    const regex = /^[A-Z2-9]+$/gm;
    const result = regex.test(newSerial);
    setValidSerialNumber(serial.length === 0 || result);
    setSerial(newSerial);
  }, [serial]);

  const handleDecreaseClick = () => {
    disptach(actionDecreaseNumberOfCLick(stepperChildrenLength));
  };

  const handleSerialNumber = (event: FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget.value;
    setSerial(target);
    setClearValue(false);
  };

  const handleUpdate = async () => {
    setDisplayLoader(true);
    try {
      const pendingFirmware = await FirmwareStorage.default.fetchPendingFirmware("BioMea");
      const macAddress = await ReportsStorage.fetchMACAddress(serial);
      const firmware = await FirmwareStorage.default.fetchFirmware("BioMea", macAddress);

      Boolean(firmware) ? setDisplayLoader(false) : setDisplayLoader(false);
      if (pendingFirmware.isNewerThan(firmware)) {
        setStellaHasUpdate(true);
        disptach(actionOpenModal("advanceUpdate"));
        await FirmwareStorage.default.updateFirmware("BioMea", macAddress);
      } else {
        setStellaHasUpdate(false);
        disptach(actionOpenModal("advanceUpdate"));
      }
      setClearValue(true);
    } catch (error) {
      setDisplayLoader(false);
      disptach(actionOpenModal("error"));
    }
  };

  return (
    <Wrapper>
      {modalType === "advanceUpdate" ? (
        <Modal>
          <AdvanceUpdateModal
            description={
              stellaHasUpdate
                ? `${t("your-stella-bio")} ${serial} ${t("is-updating")}`
                : `${t("your-stella-bio")} ${serial} ${t("is-current")}`
            }
          />
        </Modal>
      ) : modalType === "error" ? (
        <Modal>
          <DisplayError errorMsg={t("invalid-serial-number")} />
        </Modal>
      ) : null}
      <div className="heading">
        <h2 className="italic">
          Stella <span>BIO</span>
        </h2>
        <h2 className="italic">updater</h2>
      </div>
      <div className="description">
        <p>
          {t("serial-number-on-sticker")} ( <img src={serialNumber2} alt="sn" /> ).
        </p>
      </div>

      <div className="img-container">
        <img src={serialNumber1} alt="Stella-bio" />
      </div>
      <p>{t("enter-serial-number")}</p>
      <Input onChange={handleSerialNumber} maxLength={8} value={serial} clearValue={clearValue}></Input>
      {!validSerialNumber ? (
        <p className="error">{t("serial-must-have")}</p>
      ) : serial.length > 0 && serial.length !== 8 ? (
        <p className="error"> {t("serial-must-have-char")}</p>
      ) : null}
      {displayLoader ? <Loader /> : null}

      <div className="btn-container">
        <Button handle={handleDecreaseClick}>
          <img className="previous" src={previous} alt="title" />
        </Button>
        <Button disabled={!(validSerialNumber && serial.length === 8)} handle={handleUpdate}>
          {t("update")}
        </Button>
      </div>
    </Wrapper>
  );
}
