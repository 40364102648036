import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
    h2 {
      text-align: center;
    }
    span {
      font-size: 0.7em;
      margin-bottom: 2em;
    }
  }

  .scrolling-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 2;
    min-width: 170px;
    height: 500px;
    position: relative;
    flex-wrap: wrap;
    overflow-x: auto;
    background: #6fc8dd;

    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;

    @media (min-width: 1100px) {
      min-width: 600px;
    }

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: white;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.stellaBlue};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 10em;

    @media (min-width: 1100px) {
      flex-direction: row;
      justify-content: space-around;
      max-width: 100%;

      button {
        max-width: 30%;
      }

      .previous {
        width: 30%;
      }
    }

    button {
      width: 70%;
      margin: 10px;
    }

    .previous {
      width: 70%;
    }
  }
`;
