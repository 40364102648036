import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: -30px;
  left: 0;
  width: 250px;
  .alert {
    width: 100%;
    height: auto;
  }
`;
