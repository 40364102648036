import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .img-container {
    width: 60%;
    margin: 30px 0 30px 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;

    .italic {
      font-style: italic;
      span {
        font-style: normal;
        font-weight: bold;
      }
    }
    h2 {
      font-weight: 300;
      margin: 0;
    }

    p {
      margin: 10px 0;
      text-align: center;
      line-height: 140%;

      span {
        width: 2em;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 0.7em;
        border: 3px solid white;
        padding: 0 0.4em;
        margin: 2px;
      }
    }
  }

  .description {
    p {
      font-size: 0.6em;
      line-height: 1.2;
      font-family: "Exo";
      text-align: center;
      span {
        border: 2px solid white;
        font-size: 0.6em;
        font-weight: bold;
        padding: 0.2em 0.3em;
      }
      img {
        height: 1em;
        vertical-align: middle;
      }

      @media (min-width: 550px) {
        font-size: 0.8em;
      }

      @media (min-width: 1400px) {
        font-size: 0.9em;
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 10em;
    margin-top: 1em;

    button {
      width: 14em;
      height: 2em;
      margin: 10px;
    }

    @media (min-width: 1100px) {
      flex-direction: row;
      justify-content: space-around;
      max-width: 100%;

      button {
        max-width: 30%;
      }
    }
  }

  .dsc-advance-options {
    font-size: 0.5em;
    text-decoration: underline;
  }

  input {
    margin-top: 1.5em;
  }

  p {
    text-align: center;
  }

  .error {
    color: #dc2b19;
    font-size: 0.6em;
    margin-top: 0.5em;
  }
`;
