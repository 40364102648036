//Styles
import { Wrapper } from "./Loader.styles";

function Loader() {
  return (
    <Wrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
}

export default Loader;
