import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { MyProps } from "./utils/theme";

export const GlobalStyle = createGlobalStyle<MyProps>`
  ${normalize}
  body {
    background: ${({ theme }) => `linear-gradient(135deg,${theme.colors.stellaBlue2}, ${theme.colors.stellaSealBlue})`};
    font-family: 'Exo';
    font-size:${({ theme }) => theme.spacing.xs}em;
    margin:0;
    padding:0;
    box-sizing:border-box;
  }

  p {
    font-size:0.7em;
    margin:0;
  }

  .wrapper {
    display:flex;
    justify-content:center;
    width:90%;
    margin:0 auto ;
    
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 10em;
    margin-top: auto;
    margin-bottom:100px;

    @media (min-width: 1100px) {
      flex-direction: row;
      justify-content: space-between;
      max-width: 100%;
    }

    button {
      max-width: 80%;
      margin: 10px;
    }
  }


`;
