import React, { ChangeEventHandler } from "react";

import { Wrapper } from "./Input.styles";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  maxLength: number;
  value: string;
  clearValue?: boolean;
};

export function Input({ onChange, maxLength, value, clearValue }: Props) {
  return <Wrapper onChange={onChange} maxLength={maxLength} value={clearValue ? "" : value}></Wrapper>;
}
