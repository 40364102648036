import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  img {
    width: 1.4em;
  }
`;
