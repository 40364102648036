import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.3em;
  padding: 10px;
  cursor: pointer;

  .stella-blue {
    position: relative;
    height: 170px;
    .glow {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  &.select {
    .stella-blue {
      .glow {
        opacity: 0.7;
      }
    }
  }
  img {
    width: auto;
    height: 100%;
  }

  p {
    font-size: 0.5em;
    margin: 0.3em;
  }

  .checkbox {
    width: 25%;
    height: auto;
    position: absolute;
    bottom: 20%;
    right: -5%;
  }
`;
