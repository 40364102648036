//Outter
import { useTranslation } from "react-i18next";

//Styles
import { Wrapper } from "./UpdateModal.styles";
import stella_bio_purple_led from "../../../../../images/stella_bio_purple_led.png";
import loading from "../../../../../images/loading.png";

function UpdateModal() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="update_title">
        <img src={loading} alt="" />
        <h2>{t("updating-stella")}</h2>
      </div>
      <div className="update_image">
        <img src={stella_bio_purple_led} alt="" />
      </div>
      <div className="footer">
        <p className="bold">{t("dont-use-during-update")}</p>
        <p>
          {t("update-process")} <span>{t("lights-up-white-blue")}</span>
        </p>
      </div>
    </Wrapper>
  );
}

export default UpdateModal;
