//Outer
import { Firmware } from "@egzotech/stella-bio-js";
import { useTranslation } from "react-i18next";

//Styles
import { Wrapper } from "./UpdateFinish.styles";
import stella_bio_purple_led from "../../../../../images/stella_bio_purple_led.png";

function UpdateFinish({ firmware }: { firmware: Firmware | null }) {
  const { t } = useTranslation();
  const handleRefreshPage = () => {
    window.location.reload();
  };
  return (
    <Wrapper>
      <div className="update_title">
        <h2>{t("update-finish")}</h2>
      </div>
      <div className="update_image">
        <img src={stella_bio_purple_led} alt="" />
      </div>
      <div className="footer">
        <p className="bold">
          {t("current-version")} {firmware?.version}
        </p>
        <button onClick={handleRefreshPage}>{t("update-next-stella")}</button>
      </div>
    </Wrapper>
  );
}

export default UpdateFinish;
